import React, { Component } from 'react';
import Web3 from 'web3'
import Web3Modal from "web3modal";
import MerkleTree from 'merkletreejs';
import keccak256 from "keccak256"
import logo from './images/logo.png';
import vial from './images/vial-round.png';
import './App.css';
import CMLC from './abis/CMLC.json'
import {potion0Merkle} from './wallets/potion_0_merkle'
import {potion0Lookup} from './wallets/potion_0_wallets_dict'
import { providerOptions } from "./providerOptions";

class App extends Component {
  componentDidMount() {
    // call api or anything
    console.log("Component has been rendered");
    const leafNodes = potion0Merkle.map(a => Web3.utils.soliditySha3(a[0], a[1]))
		const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })
    this.setState({ potion0Tree: merkleTree})

    console.log(merkleTree.toString())
  }

  ethEnabled = async () => {
    this.setState({isError: false, errorMessage: ''})

    try {

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
      });
      
      const provider = await web3Modal.connect()
      
      const web3 = new Web3(provider)

      const networkId = await web3.eth.net.getId()
      const accounts = await web3.eth.getAccounts()
      console.log(accounts[0])
      console.log(networkId)

      if (accounts.length) {
        this.setState({statusUpdate: 'Connected to ' + accounts[0].substring(0, 5) + '....' + accounts[0].substring(accounts[0].length - 5)})
    
        this.setState({ account: accounts[0]})
        if (networkId === 1) {
          const abi = CMLC.abi
          const contract = new web3.eth.Contract(abi, '0xd220a59FF7a5856C20Cc0ba007DC43B3a47161f8')
          this.setState({ contract })

         
          const minted = [0]
          
          // check on contract if they claimed
          const didClaim = await contract.methods.checkClaimed(accounts[0]).call()

          // set if they claimed
          console.log('didClaim ' + didClaim)
          this.setState({ didClaim : didClaim})
        
          // get local claim amount
          const claim = [0]
          if (potion0Lookup.hasOwnProperty(accounts[0])) {
              claim[0] = potion0Lookup[accounts[0]]         
          }

          // set local claim amount
          console.log("claim  " , claim)
          this.setState({ claimAmount : claim })
          console.log("claimAmount state  " , this.state.claimAmount[0])

          // check if we can show free claim section
          let totalClaimed = claim.reduce((partialSum, a) => partialSum + a, 0)
          if (totalClaimed > 0) {
            this.setState({ canFreeClaim: true})
          }

          // disable claim button
          const isDisabledClaim = [true]
          if( (this.state.claimAmount[0] - this.state.didClaim[0]) <= 0 ) {
            
            this.setState({ claimAmount : [0] })
            console.log('you cant claim')
          } else {
            this.setState({ claimAmount : [this.state.claimAmount[0] - this.state.didClaim[0]] })
            isDisabledClaim[0] = false 

            console.log('you can claim did not work did Claim ' + this.state.didClaim[0] + ' claimamount '+ this.state.claimAmount[0])
          }
          
          // this.state.numberMinted[i] <= this.state.totalPublicMint[i]
          // console.log(claim)
          
          this.setState({ isDisabled : isDisabledClaim })
          
          //this.setState({ numberMinted : minted })
          console.log('isDisabled' + this.state.isDisabled)
          console.log('potion minted' + this.state.numberMinted)

        } else {
          this.setState({statusUpdate: 'Error'})
          this.setState({isError: true, errorMessage: 'Smart contract not deployed to detected network'})
        }
      } else {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'We did not find any accounts.'})
      }

    } catch (error) {
      console.log(error)
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: 'Wallet Connection Error: ' + error.message})
    }
  }

  mint = (potionId, numberToMint) => {
    this.setState({statusUpdate: 'Confirm Transaction...'})
    this.setState({isError: false, errorMessage: ''})
    numberToMint = parseInt(numberToMint)
    if (numberToMint <= 0) {
      this.setState({isError: true, errorMessage: 'You did not enter a quantity to mint.'})
    } else if (numberToMint <= 8) {
      this.state.contract.methods.mint(potionId, numberToMint).send({ 
        from: this.state.account,
        value: numberToMint *  this.state.mintPrice[potionId]})
      .on('transactionHash', (hash) => {
        this.setState({statusUpdate: 'Potion Mint Started...'})
      })
      .on('receipt', (receipt) => {
        this.setState({statusUpdate: 'Potion Minted, Check Your Wallet!'})
        //this.getNumberMinted()
      })
      .on('error', (error, receipt) => {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: error.message})
      })
    } else {
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: 'You tried minted more than allowed 8. '})
    }
  }

  claim = (potionId, numberToMint) => {
    this.setState({statusUpdate: 'Confirm Transaction...'})
    this.setState({isError: false, errorMessage: ''})
    numberToMint = parseInt(numberToMint)
    potionId = parseInt(potionId)
    let leaf = ''
    let merkleProof = ''
    switch(potionId) {
      case 0:
        leaf = Web3.utils.soliditySha3(this.state.account, numberToMint)
        merkleProof = this.state.potion0Tree.getHexProof(leaf)
        console.log(merkleProof)
        break
      
      default:
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'You did not select a potion'})
        break
    }
    console.log(merkleProof)
    this.state.contract.methods.mintPresaleMerkle(numberToMint, numberToMint, merkleProof).send({ 
      from: this.state.account
    })
    .on('transactionHash', (hash) => {
      this.setState({statusUpdate: 'Potion Claim Started...'})
    })
    .on('receipt', (receipt) => {
      this.setState({statusUpdate: 'Potion Claimed, Check Your Wallet!'})
      this.resetClaimedMinted()
    })
    .on('error', (error, receipt) => {
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: error.message})
    })
  }

  resetClaimedMinted = async () => {
    try {
      const _didClaim = await this.state.contract.methods.checkClaimed(this.state.account).call()          
      this.setState({ didClaim : _didClaim })
      const isDisabledClaim = [true, true, true, true]
      const claim = this.state.claimAmount
      for (let i = 0; i < 1; i++) {
        if(!this.state.didClaim[i] && (this.state.claimAmount[i] > 0)) {
          isDisabledClaim[i] = false 
        } else {
          claim[i] = 0
          console.log('did not work did Claim' + this.state.didClaim[i] + ' claimamount '+ this.state.claimAmount[i])
        }
      }
      this.setState({ isDisabled : isDisabledClaim })
      this.setState({ claim : claim })
    } catch  (error)  {
      console.log(error)
    }
  }

  // getNumberMinted = async () => {
  //   try {
  //     const minted = [0,0,0,0]          
  //     for (let i = 0; i < 4; i++) {
  //       let potion = await this.state.contract.totalSupply().call()
        
  //       minted[i] = potion.numMintedPublic
  //     }
  //     this.setState({ numberMinted : minted })
  //   } catch  (error)  {
  //     console.log('Contract Call Did not work')
  //   }
  // }

  constructor(props) {
    super(props)
    this.state = {
      potion0Tree: '',
      potion1Tree: '',
      potion2Tree: '',
      potion3Tree: '',
      mintPrice: [0],
      isDisabled: [true],
      canFreeClaim: false,
      test1: true,
      didClaim: [0],
      claimAmount: [0],
      numberMinted: [0],
      totalPublicMint: [500],
      account: '',
      contract: null,
      totalSupply: 0, 
      isError: false,
      errorMessage: '',
      statusUpdate: 'Wallet Connected',
      mintOpen: false
    }
  }

  render() {
    return (
      <div className="App">
        
          <div className="body-display large">
          <br/>
          <img src={logo} style={{width:"50%"}}alt="Logo" />
          <br/><br/>
          <img src={vial} style={{width:"35%"}}alt="Logo" />
          <br/><br/>
          <h2>Calaveras Cocktail</h2><br/>
          The Calaveras Cocktail can be used to create zombies and is free to CryptoMonster holders. You can claim one Calaveras Cocktail for every CryptoMonster you have in your wallet. Free to mint + gas
            <strong className="bold-text">
              <br/><br/>
              { this.state.account && 
                <div><span className="status-text">STATUS: {this.state.statusUpdate}</span></div>
              }
              { this.state.isError &&
                  <div className="error">{ this.state.errorMessage }</div>
              }
            </strong>
          </div>
          <br />
          
          { !this.state.account &&
            <div>
            <form onSubmit={(event) => {
              event.preventDefault()
              this.ethEnabled()
            }}>
              <input
                type='submit'
                className="button connect-button"
                value="CONNECT WALLET"
              /><br/>
             
            </form>
            </div>
          }
          { this.state.account && this.state.canFreeClaim &&            
            <div className="four-container">
              <div className="centered">
                <form onSubmit={(event) => {
                    event.preventDefault()
                    this.claim(0, this.state.claimAmount[0])
                  }}>
                    <h3>You can claim: {this.state.claimAmount[0]} cocktail(s)</h3>
                    <input
                      type='submit'
                      className="button mint-button green"
                      disabled={this.state.isDisabled[0]}
                      value="Claim Cocktail(s)"
                    />
                  </form>
              </div>
              <br/> <br/> <br/> <br/> <br/>
            </div>
          }
      </div>
      
    )
  };
}

export default App;
